/* global analytics:false */
const RESERVED_ATTRIBUTES = [
  'event',
  'view-event',
  'load-event',
  'load-action',
  'view-action',
  'manual-view',
  'manual-load',
];

function getElementProperties(el) {
  const dataset = el.dataset;

  return {
    app_type: dataset.appType,

    // cta specific
    cta_type: dataset.ctaType,
    cta_location: dataset.ctaLocation,
    cta_topic: dataset.ctaTopic,
    cta_version: dataset.ctaVersion,
    cta_template: dataset.ctaTemplate,

    // directory data

    location_id: dataset.idHash,
    is_solv_partner: dataset.isSolvPartner === 'true' || undefined,
    is_bookable: dataset.isBookable === 'true' || undefined,
    is_viewable: dataset.isViewable,
    platform_type: dataset.platformType,
    city: dataset.directoryCity,
    state: dataset.directoryState,
    version: dataset.version,
    listing_card_rank: dataset.cardRank,

    // article data
    article_title: dataset.articleTitle,
    date_published: dataset.articlePublished,
    date_updated: dataset.articleUpdated,
    image: dataset.articleImage,
    author: dataset.articleAuthor,
    word_count: dataset.articleWordCount,
    specialty: dataset.articleSpecialty,

    // link data
    link_topic: dataset.linkTopic,
    link_type: dataset.linkType,
    link_text: dataset.linkText,
    link_location: dataset.linkLocation,
    link_color: dataset.linkColor,
    link_address: dataset.linkAddress,
    ...getSegmentGlobbedProperties(el),
  };
}

function getContextProperties(event) {
  const bodyDataset = document.body.dataset;

  let segmentGlobalProperties = getSegmentGlobbedProperties(document.body);
  let directoryContents = getDirectoryContents(bodyDataset, event);
  let updatedGlobalProperties = updateGlobalProperties(
    segmentGlobalProperties,
    directoryContents,
  );

  return {
    isMobile: window.innerWidth < 769,
    ...updatedGlobalProperties,
    ...directoryContents,
  };
}

// This will now pick up all attributes that start with "data-segment" and snake_case them for return
// We only need to explicitly define attributes on the dataset that cannot match this format
function getSegmentGlobbedProperties(el) {
  const globbedProperties = {};

  // TODO: refactor the views to hold to this format
  for (const key of el.getAttributeNames()) {
    if (key.startsWith('data-segment-')) {
      const rawKey = key.replace('data-segment-', '');
      if (RESERVED_ATTRIBUTES.includes(rawKey)) {
        continue;
      } else {
        const snakeCaseKey = rawKey.replaceAll('-', '_');
        globbedProperties[snakeCaseKey] = el.getAttribute(key);
      }
    }
  }
  return globbedProperties;
}

// gets object of directory results for directory load event
function getDirectoryContents(dataset, event) {
  const eventsWithResultsContain = [
    'Directory - Load',
    'Directory - Slots',
    'Directory - CDP Card',
    'Directory - CTA',
    'Directory - Profile Card',
  ];
  if (eventsWithResultsContain.includes(event)) {
    let results_contain = {
      partner_count: (dataset.segmentPartnerCount ||= '0'),
      nonpartner_count: (dataset.segmentNonpartnerCount ||= '0'),
      nearby_bookable_count: (dataset.segmentNearbyBookableCount ||= '0'),
      nearby_results_count: (dataset.segmentNearbyResultsCount ||= '0'),
      telemed_count: (dataset.segmentTelemedCount ||= '0'),
    };
    let affiliate_partnerships = {
      growtherapy: (dataset.segmentGrowtherapy ||= '0'),
      quest_health: (dataset.segmentQuestHealth ||= '0'),
      labcorp: (dataset.segmentLabcorp ||= '0'),
    };

    return {
      results_contain: results_contain,
      affiliate_partnerships: affiliate_partnerships,
    };
  } else {
    return {};
  }
}

function updateGlobalProperties(globalProperties, directoryContents) {
  if (directoryContents.results_contain) {
    delete globalProperties.partner_count;
    delete globalProperties.nonpartner_count;
    delete globalProperties.nearby_bookable_count;
    delete globalProperties.nearby_results_count;
    delete globalProperties.telemed_count;
  }
  if (directoryContents.affiliate_partnerships) {
    delete globalProperties.growtherapy;
    delete globalProperties.quest_health;
    delete globalProperties.labcorp;
  }

  return globalProperties;
}

function trackDOMEvent(e, custom_properties = {}) {
  const el = e.currentTarget;
  if (el.dataset.preventSegmentTracking !== undefined) {
    return;
  }
  const event = el.dataset.segmentEvent;
  const listingProperties = getElementProperties(el);
  const contextProperties = getContextProperties(event);

  return trackSegmentEvent(event, {
    ...listingProperties,
    ...contextProperties,
    ..._removeUndefined(custom_properties),
  });
}

function trackLoadEvent(el) {
  const event = el.dataset.segmentLoadEvent;
  const action = el.dataset.segmentLoadAction || 'load';
  const listingProperties = getElementProperties(el, event);
  const contextProperties = getContextProperties(event);
  const updatedListingProperties = updateGlobalProperties(
    listingProperties,
    contextProperties,
  );

  return trackSegmentEvent(event, {
    ...updatedListingProperties,
    ...contextProperties,
    action,
  });
}

function trackViewEvent(el) {
  const event = el.dataset.segmentViewEvent;
  const action = el.dataset.segmentViewAction || 'view';
  const listingProperties = getElementProperties(el);
  const contextProperties = getContextProperties(event);
  const updatedListingProperties = updateGlobalProperties(
    listingProperties,
    contextProperties,
  );

  return trackSegmentEvent(event, {
    ...updatedListingProperties,
    ...contextProperties,
    action,
  });
}

function trackSegmentEvent(event, properties, options, callback) {
  if (typeof analytics !== 'undefined') {
    analytics.track(event, _removeUndefined(properties), options, callback);
  } else {
    console.debug(
      'Not tracking event as Segment Analytics is not loaded',
      event,
      _removeUndefined(properties),
    );
  }
}

function getAnonymousId() {
  try {
    if (typeof analytics !== 'undefined') {
      return analytics.user().anonymousId();
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

function getDistinctId() {
  let distinctId;

  // Iterate over all local storage items
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.includes('mp_') && key.endsWith('_mixpanel')) {
      const data = localStorage.getItem(key);
      if (data) {
        try {
          const parsedData = JSON.parse(data);
          if (parsedData && parsedData.distinct_id) {
            distinctId = parsedData.distinct_id;
            break;
          }
        } catch (e) {}
      }
    }
  }

  return distinctId?.toLowerCase() !== 'undefined' ? distinctId : undefined;
}

function _removeUndefined(obj) {
  Object.keys(obj).forEach((key) =>
    obj[key] === undefined ? delete obj[key] : {},
  );
  return obj;
}

export {
  trackDOMEvent,
  trackViewEvent,
  trackSegmentEvent,
  trackLoadEvent,
  getContextProperties,
  getElementProperties,
  getAnonymousId,
  getDistinctId,
};
